.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.table-container {
  overflow: auto;
}

table {
  text-align: left;
  color: black;
  border: 1px solid #d8d8ff;
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 10px;
  margin: 0 2.5% 0 2.5%;
  background-color: white;

  th {
    cursor: pointer;
  }
  th, td {
    padding: 12px 15px;
    text-align: left;
  }
  
  th {
    color: black;
    font-weight: bold;
    border-bottom: 2px solid #dddddd;
  }

  th:hover {
    background-color: white;
  }
  
  td {
    border-bottom: 1px solid #dddddd;
  }
  
  tr:hover {
    background-color: #f7fbff;
  } 
}

.table-date {
  font-size: 12px;
  color:gray;
}

.table-more-votes {
  color: black;
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
  margin: 10px 2.5% 10px 2.5%;
}

.hero-container {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 500px; /* Adjust height as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 1792px;
  margin: 0 2.5% 0 2.5%;
  background-image:url("./assets/hero.webp");
  display: flex;
  justify-content: center;
  border-bottom: 1px solid black;
}

.hero-search-box {
  background-color: #2e86c8;
  border: 1px solid black;
  border-radius: 30px;
  max-width: 500px;
  width: 50%;
  text-align: left;
  padding: 40px;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;

  button {
    max-width: fit-content;
  }
}

.contents-container {
  background: white;
  padding-bottom: 10px;
}

.mep-container {
  padding: 2.5%;
  display: flex;
  flex-direction: row;
  overflow: auto;
}

.mep-details {
  border: 1px solid #d8d8ff;
  color: black;
  font-weight: 400;
  font-size: 12px;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 5px;
  display:flex;
  flex-direction: column;
  justify-content: center;

  .mep-details__info {
    margin-left: 10px;
  }

  .group-tag {
    background-color: lightgray;
    border-radius: 4px;
    width: fit-content;
    padding: 5px;
  }
}

.avatar {
  margin-left: 10px;
  width: 50px; /* Adjust width as needed */
  height: 50px; /* Adjust height as needed */
  overflow: hidden; /* Clip image if it overflows */
  border-radius: 50%; /* Set 50% for a circle */
  position: relative; /* Needed for centering */

}

.avatar img {
  height: 100%; /* Display image at full height */
  position: absolute; /* Allow absolute positioning */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center image */
}

.member-links {
  color: black;
  display: flex;
  justify-content: space-evenly;
  p {
    text-decoration: underline;
  cursor:pointer
  }
  
}

.vote-details-container {
  margin: 0 2.5% 0 2.5%;
}

.vote-details-view {
  background-color: aliceblue;
  border-radius: 4px;
  padding: 25px 5px 50px 5px;

  span {
    font-weight: 800;
  }
}

.vote-details-facts {
  font-weight: 400;
}

.blue-button {
  width: 60px;
  background-color: #004494;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 10px;
  cursor: pointer;

  &:hover {
    background-color: #003366;
  }
}

.for {
  background: green;
  color: white;
  border: none;
}

.abstention {
  background: white;
  color: black;
  border: 1px solid grey;
}

.against {
  background: red;
  color: white;
  border: none;
}

.did-not-vote {
  background: grey;
  color: white;
  border: none;
}
