body {
  background: rgb(0,68,148);
background: linear-gradient(174deg, rgba(0,68,148,1) 0%, rgba(0,68,148,1) 40%, rgba(0,60,130,1) 100%);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

h1 {
  color: white;
  font-size: 32px;
  font-weight: 500;
  font-family: Montserrat;
}

button {
  border-radius: 4px;
  background: none;
  border: solid 1px #FFD700;
  margin: 10px 0 10px 0;
  padding: 5px 10px 5px 10px;
  color: #FFD700;
  cursor: pointer;

  &:hover {
    background-color: #1f7fc7;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tag {
  background-color: #FFD700;
  color: #ffffc6;
  font-weight: 800;
  border:1px solid #ffffc6;
  border-radius: 4px;
  padding: 4px;
  white-space: nowrap
}

a {
  color: black
}
